<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        vid="email"
        name="E-mail"
        rules="required|email"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="emailInput">Email address</label>
          <input
            type="email"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="emailInput"
            aria-describedby="emailHelp"
            v-model="user.email"
            placeholder="Enter email"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/auth/password-reset1" class="float-right">
            Forgot password?
          </router-link>
          <input
            type="password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="passwordInput"
            v-model="user.password"
            placeholder="Password"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType" />
          <label class="custom-control-label" :for="formType"
            >Remember Me</label
          >
        </div> -->
      <!-- <button type="submit" class="btn btn-primary float-right">Sign in</button> -->
      <!-- </div> -->
      <div class="mt-4">
        <button type="submit" class="btn btn-primary btn-block">Sign in</button>
        <span class="dark-color d-flex line-height-2 my-2">
          <hr class="w-25" />
          Atau masuk dengan
          <hr class="w-25" />
        </span>
        <!-- <a href="javascript:void(0)" class="btn btn-secondary btn-block">SSO</a> -->
      </div>
      <!-- <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Don't have an account?
            <router-link :to="{ name: 'auth1.sign-up1' }" class="iq-waves-effect pr-4">
              Sign up
            </router-link>
          </span>
        <social-login-form></social-login-form>
      </div> -->
    </form>
    <div>
      <button class="btn btn-secondary btn-block" @click="loginWithSso()">
        <div class="text-primary-blue-dark font-bold">SSO</div>
      </button>
    </div>
    <router-link
      :to="{ name: 'dashboard.home-4' }"
      class="btn btn-outline-primary btn-block mt-4"
      ><i class="ion-android-arrow-back"></i> Kembali</router-link
    >
  </ValidationObserver>
</template>

<script>
// import auth from '@/services/auth'
// import firebase from 'firebase'
// import SocialLoginForm from './SocialLoginForm'
// import { core } from '@/config/pluginInit'
import msal from '@/config/aadService'
// import Vue from 'vue'
// import { ID_GROUP } from '@/types/index'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SignIn1Form',
  // components: { SocialLoginForm },
  // props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: ''
    }
  }),
  mounted() {
    // msal.autoLogin()
    // this.user.email = this.$props.email
    // this.user.password = this.$props.password
  },
  computed: {
    ...mapGetters({
      // stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    ...mapActions('authService', ['postLogin', 'postLogout', 'getCurrentUser']),
    onSubmit() {
      this.sendRequest()
      //   if (this.formType === 'passport') {
      //     this.passportLogin()
      //   } else if (this.formType === 'jwt') {
      //     this.jwtLogin()
      //   } else if (this.formType === 'firebase') {
      //     this.firebaseLogin()
      //   }
    },
    async sendRequest() {
      // e.preventDefault()
      // if (this.a + this.b === parseInt(this.vAnswer)) {
      await this.postLogin({
        email: this.user.email,
        password: this.user.password
      })
        .then(res => {
          localStorage.setItem('user', res.success)
          localStorage.setItem('access_token', res.token)
          this.$router.push({ name: 'sidebar.dashboard' })
          // Check ID Group
          // this.getCurrentUser().then(usr => {
          //   // hak akses: kasubag tu [belum ada groupnya], regjian (admin upbjj) & puskom
          //   if (
          //     [
          //       ...ID_GROUP.UPBJJ_ADMIN,
          //       ...ID_GROUP.DAAK,
          //       ...ID_GROUP.PUSKOM
          //     ].includes(String(usr.id_group))
          //   ) {
          //     this.$router.push({ name: 'sidebar.dashboard' })
          //   } else {
          //     this.postLogout()
          //     Vue.$toast.error('Anda tidak memiliki hak akses')
          //   }
          // })
          // clearInterval(this.$timeout.logoutTimeout)
          // this.$timeout.logoutTimeout = setTimeout(
          //   async function () {
          //     if (this.$route?.matched?.[0]?.path !== 'sign-in') {
          //       await this.postLogout().then(() =>
          //         this.$toast.error('Sesi telah habis')
          //       )
          //     }
          //   }.bind(this),
          //   parseInt(process.env.VUE_APP_LOGOUT_TIMEOUT)
          // )
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log('aaa', err)
          this.resetInput()
        })
      // } else {
      //   this.$toast.error('Captha Salah')
      //   this.resetInput()
      // }
    },
    async loginWithSso() {
      await msal.login()
    },
    /**
     * @description
     * 📖 Mereset semua input menjadi kosong kembali.
     */
    resetInput() {
      this.user.email = ''
      this.user.password = ''
    }
    // passportLogin () {
    //   auth.login(this.user).then(response => {
    //     if (response.status) {
    //       localStorage.setItem('user', JSON.stringify(response.data))
    //       this.$router.push({ name: 'sidebar.dashboard' })
    //     } else if (response.data.errors.length > 0) {
    //       this.$refs.form.setErrors(response.data.errors)
    //     }
    //   }).finally(() => { this.loading = false })
    // },
    // jwtLogin () {
    //   const selectedUser = this.stateUsers.find(user => {
    //     return (user.email === this.user.email && user.password === this.user.password)
    //   }) || null
    //   if (selectedUser) {
    //     this.$store.dispatch('Setting/authUserAction', {
    //       auth: true,
    //       authType: 'jwt',
    //       user: {
    //         id: selectedUser.uid,
    //         name: selectedUser.name,
    //         mobileNo: null,
    //         email: selectedUser.email,
    //         profileImage: null
    //       }
    //     })
    //     localStorage.setItem('user', JSON.stringify(selectedUser))
    //     localStorage.setItem('access_token', selectedUser.token)
    //     this.$router.push({ name: 'sidebar.dashboard' })
    //   }
    // }
    // firebaseLogin () {
    //   firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password).then((user) => {
    //     const firebaseUser = firebase.auth().currentUser.providerData[0]
    //     this.$store.dispatch('Setting/authUserAction', {
    //       auth: true,
    //       authType: 'firebase',
    //       user: {
    //         id: firebaseUser.uid,
    //         name: firebaseUser.displayName,
    //         mobileNo: firebaseUser.phoneNumber,
    //         email: firebaseUser.email,
    //         profileImage: firebaseUser.photoURL
    //       }
    //     })
    //     localStorage.setItem('user', JSON.stringify(firebaseUser))
    //     this.$router.push({ name: 'sidebar.dashboard' })
    //   }).catch((err) => {
    //     if (err.code === 'auth/user-not-found') {
    //       core.showSnackbar('error', 'These credentials do not match our records.')
    //     } else {
    //       core.showSnackbar('error', err.message)
    //     }
    //   })
    // }
  }
}
</script>

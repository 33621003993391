import { UserAgentApplication } from 'msal'
import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
const msalConfig = {
  auth: {
    clientId: `${process.env.VUE_APP_CLIENT_ID}`,
    authority: 'https://login.microsoftonline.com/common',
    responseType: 'code',
    responseMode: 'fragment',
    redirectUri: `${process.env.VUE_APP_REDIRECT_URI}`,
    postLogoutRedirectUri: `${process.env.VUE_APP_LOGOUT_URI}`
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}
const loginRequest = {
  scopes: [`${process.env.VUE_APP_SCOPES}`]
}
const accessTokenRequest = {
  scopes: [`${process.env.VUE_APP_SCOPES}`],
  forceRefresh: false // Set this to "true" tao skip a cached token and go to the server to get a new token
}
const myMsal = new UserAgentApplication(msalConfig)

/** PLAN 1 */
// const login = async () => {
//   // 1
//   // axios.get('https://apiaksara.ut.ac.id/api/office365/login/', { headers: { 'Access-Control-Allow-Origin': '*' } }).then(function (response) {
//   //   console.log('resu: ', response.request.res.responseUrl)
//   // }).catch(function (no200) {
//   //   console.error('404, 400, and other events')
//   // })
//   // 2
//   // window.location.replace('https://apiaksara.ut.ac.id/api/office365/login')
//   // 3
//   // const resp = await fetch('https://apiaksara.ut.ac.id/api/office365/login')
//   // console.log('resp: ', resp)
//   // const data = await resp.json()
//   // console.log('data: ', data)
//   // 4 https://apiaksara.ut.ac.id/api/office365/profile
//   const token = await axios({
//     url: 'https://apiaksara.ut.ac.id/api/office365/login',
//     method: 'GET',
//     headers: {
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET, OPTIONS',
//       'Content-Type': 'application/json'
//     }
//   })
//     .then(res => {
//       console.log('RESU: ', res.data)
//       // document.write(res.data)
//       return res
//     })
//     .catch((err) => {
//       console.log('ERR: ', err)
//     })
//   console.log('token', token)
// }

const login = async () => {
  try {
    const authResult = await myMsal.loginPopup(loginRequest).then(() => {
      return myMsal.acquireTokenSilent(accessTokenRequest)
    })
    console.log('login access token', authResult, authResult.accessToken)
    console.log('json access token', JSON.parse(atob(authResult.accessToken.split('.')[1])))
    await loginSso(authResult.accessToken)
    return authResult
  } catch (error) {
    console.log('login error', error)
  }
}
/** -- with authorization -- */
const loginSso = async token => {
  await axios({
    url: `${process.env.VUE_APP_URL_PUBLIC}/login-sso`,
    // url: 'https://apiaksara.ut.ac.id/api/login-sso',
    method: 'POST',
    headers: {
      Authorization: token
    }
  })
    .then(res => {
      console.log('result login: ', res)
      Vue.$toast.info('Berhasil Login')
      store.dispatch('authService/setToken', res.data.token)
      router.push({ name: 'sidebar.dashboard' })
    })
    .catch(() => {
      Vue.$toast.info('Email tidak tersedia')
      logOut()
    })
}
/** -- with bodyFormData -- */
// const loginSso = async token => {
//   const bodyFormData = new FormData();
//   bodyFormData.append('mstoken', token);
//   await axios({
//     url: 'https://apiaksara.ut.ac.id/api/office365/login',
//     method: 'GET',
//     data: bodyFormData,
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
//     .then(res => {
//       console.log('result login: ', res)
//       Vue.$toast.info('Berhasil Login')
//       store.dispatch('authService/setToken', res.data)
//       router.push({ name: 'sidebar.dashboard' })
//     })
//     .catch(() => {
//       Vue.$toast.info('Email tidak tersedia')
//       logOut()
//     })
// }

/** PLAN 2 */
// const login = async () => {
//   console.log('TEST')
//   await axios({
//     url: 'https://apiaksara.ut.ac.id/api/office365/login',
//     method: 'GET',
//     withCredentials: true,
//     headers: {
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET',
//       'Access-Control-Allow-Credentials': true
//     }
//   })
//     .then(res => {
//       console.log('result: ', res)
//       console.log('LOGIN SSO 1')
//       Vue.$toast.info('Berhasil Login')
//       store.dispatch('authService/setToken', res.token)
//       router.push({ name: 'sidebar.dashboard' })
//       // store.dispatch('authService/setToken', res.data)
//       // router.push({ name: 'sidebar.dashboard' })
//       // console.log('LOGIN SSO 2')
//       /** start - go login */
//       // try {
//       //   const authResult = myMsal.loginPopup().then(() => {
//       //     console.log('SSO step 2')
//       //     return myMsal.acquireTokenSilent()
//       //   })
//       //   console.log('login access token', authResult, authResult.accessToken)
//       //   Vue.$toast.info('Berhasil Login')
//       //   store.dispatch('authService/setToken', res.data)
//       //   router.push({ name: 'sidebar.dashboard' })
//       //   return authResult
//       // } catch (error) {
//       //   console.log('login error', error)
//       // }
//       /** end - go login */
//     })
//     .catch(err => {
//       console.log('EERR ', err)
//       Vue.$toast.info('Email tidak tersedia')
//       // logOut()
//     })
// }

// const autoLogin = async () => {
//   try {
//     const token = await myMsal.acquireTokenSilent(accessTokenRequest)
//     console.log('STEP SSO TOKEN', token)
//     if (token) {
//       // await loginSso(token?.accessToken)
//       return
//     }
//   } catch (err) {
//     console.log('STEP SSO ERROR', err)
//   }
// }

// const getAccount = async () => {
//   try {
//     const account = await myMsal.getAccount()
//     console.log(account)
//     return account
//   } catch (error) {
//     console.log('getAccount error', error)
//   }
// }

const logOut = async () => {
  try {
    const response = await myMsal.logout()
    console.log('berhasil logout', response)
  } catch (error) {
    console.log('getAccount error', error)
  }
}

export default {
  login,
  // getAccount,
  logOut
  // autoLogin
  // async login () {
  //   try {
  //     const authResult = await myMsal.loginRedirect().then(res => {
  //       // return myMsal.acquireTokenSilent(accessTokenRequest)
  //       console.log(res)
  //     })
  //     // console.log('login access token', authResult, authResult.accessToken)
  //     // await loginSso(authResult.accessToken)
  //     return authResult
  //   } catch (error) {
  //     console.log('login error', error)
  //   }
  // },
  // async loginSso (token) {
  //   await axios({
  //     url: 'https://apiaksara.ut.ac.id/api/office365/login',
  //     method: 'GET'
  //   })
  //     .then(res => {
  //       console.log('result: ', res)
  //       console.log('LOGIN SSO 1')
  //       Vue.$toast.info('Berhasil Login')
  //       // store.dispatch('authService/setToken', res.data)
  //       // router.push({ name: 'sidebar.dashboard' })
  //       // console.log('LOGIN SSO 2')
  //     })
  //     .catch(err => {
  //       console.log('EERR ', err)
  //       Vue.$toast.info('Email tidak tersedia')
  //       // logOut()
  //     })
  // }
}
